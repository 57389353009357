import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import TransitionAlert from './transition-alert';
import { EP, callApi } from '../api';
import useFormInput from './use-form-input';
import { toUsername } from '../utils';

const useStyles = makeStyles((theme) => ({
  alert: {
    marginTop: theme.spacing(4),
  },
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  }
}));

function TenantForm({
  action,
  tenant,
}) {
  const { t } = useTranslation('tenantForm');
  const classes = useStyles();
  const account = useFormInput(tenant ? tenant.account : '', toUsername);
  const description = useFormInput(tenant ? tenant.description : '');
  const username = useFormInput('', toUsername);
  const email = useFormInput('');
  const givenName = useFormInput('');
  const name = useFormInput('');
  const domain = useFormInput(tenant ? tenant.domain : '');
  const coupon = useFormInput(tenant && tenant.details ? tenant.details.coupon : '');
  const displayName = useFormInput(tenant && tenant.details ? tenant.details.displayName : '');
  const logoUrl = useFormInput(tenant && tenant.details ? tenant.details.logoUrl : '');
  const privacyUrl = useFormInput(tenant && tenant.details ? tenant.details.privacyUrl : '');
  // const senderEmail = useFormInput(tenant && tenant.details ? tenant.details.senderEmail : '');
  const socialFacebook = useFormInput(tenant && tenant.details ? tenant.details.socialFacebook : '');
  const socialInstagram = useFormInput(tenant && tenant.details ? tenant.details.socialInstagram : '');
  const socialLinkedin = useFormInput(tenant && tenant.details ? tenant.details.socialLinkedin : '');
  const socialTwitter = useFormInput(tenant && tenant.details ? tenant.details.socialTwitter : '');
  const initialFormState = { isSubmitting: false, isSuccess: false, error: null }
  const [form, setForm] = useState(initialFormState); 
  const isInvalidForm = (
    !account.isValid 
    || !description.isValid 
    || !domain.isValid
    || !displayName.isValid
    || !logoUrl.isValid
    || !privacyUrl.isValid
    || (action === 'add' && !username.isValid)
    || (action === 'add' && !email.isValid)
    || (action === 'add' && !givenName.isValid) 
    || (action === 'add' && !name.isValid) 
  ); 
  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setForm({ ...initialFormState, isSubmitting: true });
    const tenant = {
      account: account.value,
      description: description.value,
      domain: domain.value,
      details: {
        coupon: coupon.value,
        displayName: displayName.value,
        logoUrl: logoUrl.value,
        privacyUrl: privacyUrl.value,
        // senderEmail: senderEmail.value,
        socialFacebook: socialFacebook.value,
        socialInstagram: socialInstagram.value,
        socialLinkedin: socialLinkedin.value,
        socialTwitter: socialTwitter.value,
      }
    }
    const owner = (
      action === 'add' 
      ?  {
          username: username.value,
          email: email.value,
          givenName: givenName.value,
          name: name.value,
        }
      : null
    );
    const params = (
      action === 'add'
      ? {
          body: {
            tenant,
            owner,
          },
        }
      : {
          body: tenant,
          tenantId: tenant.id,
        }
    );
    try {
      const endpoint = action === 'add' ? EP.tenants.post : EP.tenants.patch;
      await callApi(endpoint, params);
      setForm({ ...initialFormState, isSuccess: true});
    } catch(error) {
      const message = (error.response && error.response.data && error.response.data.message)
        || 'Error saving tenant'; 
      setForm({ ...initialFormState, error: message });
    }
  }
  const isAddSuccess = form.isSuccess && action === 'add';
  return (
    <Container component="main" maxWidth="xs">
      { isAddSuccess && (
        <TransitionAlert className={classes.alert} severity="success" isOpen={form.isSuccess}>{t('successMessage')}</TransitionAlert>
      )}
      { form.isSuccess && (
        <Button 
          className={classes.buttons}
          component={Link}
          to="/tenants" 
          fullWidth  
          variant="contained"
          color="primary"
        >
            {t('seeAllTenants')}
        </Button>
      )}
      { form.isSuccess && action ==='add' && (
        <Button 
          className={classes.buttons}
          fullWidth  
          variant="outlined"
          onClick={() => setForm(initialFormState)}
        >
            {t('addAnotherTenant')}
        </Button>
      )}
      { !isAddSuccess && (
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            { action === 'add' ? t('addTenant') : t('editTenant') }
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              inputProps={{
                readOnly: Boolean(form.isSubmitting),
              }}
              autoFocus
              readOnly={action === 'edit'}
              fullWidth
              id="account"
              label={t('labelAccount')}
              margin="normal"
              name="account"
              required
              type="text"
              variant="outlined"
              value={account.value}
              onChange={account.onChange}
            />
            <TextField
              inputProps={{
                readOnly: Boolean(form.isSubmitting),
              }}
              readOnly={action === 'edit'}
              fullWidth
              id="description"
              label={t('labelDescription')}
              margin="normal"
              name="description"
              required
              type="text"
              variant="outlined"
              value={description.value}
              onChange={description.onChange}
            />
            { action === 'add' && (
              <React.Fragment>
                <TextField
                  inputProps={{
                    readOnly: Boolean(form.isSubmitting),
                  }}
                  autoFocus
                  readOnly={action === 'edit'}
                  fullWidth
                  id="username"
                  label={t('labelOwnerUsername')}
                  margin="normal"
                  name="username"
                  required
                  type="text"
                  variant="outlined"
                  value={username.value}
                  onChange={username.onChange}
                />
                <TextField
                  inputProps={{
                    readOnly: Boolean(form.isSubmitting),
                  }}
                  readOnly={action === 'edit'}
                  fullWidth
                  id="email"
                  label={t('labelOwnerEmail')}
                  margin="normal"
                  name="email"
                  required
                  type="email"
                  variant="outlined"
                  value={email.value}
                  onChange={email.onChange}
                />
                <TextField
                  inputProps={{
                    readOnly: Boolean(form.isSubmitting),
                  }}
                  fullWidth
                  id="givenName"
                  label={t('labelOwnerFirstname')}
                  margin="normal"
                  name="givenName"
                  required
                  type="text"
                  variant="outlined"
                  value={givenName.value}
                  onChange={givenName.onChange}
                />
                <TextField
                  inputProps={{
                    readOnly: Boolean(form.isSubmitting),
                  }}
                  fullWidth
                  id="name"
                  label={t('labelOwnerLastname')}
                  margin="normal"
                  name="name"
                  required
                  type="text"
                  variant="outlined"
                  value={name.value}
                  onChange={name.onChange}
                />
              </React.Fragment>
            )}
            <TextField
              inputProps={{
                readOnly: Boolean(form.isSubmitting),
              }}
              readOnly={action === 'edit'}
              fullWidth
              id="domain"
              label={t('labelDomain')}
              margin="normal"
              name="domain"
              required
              type="text"
              variant="outlined"
              value={domain.value}
              onChange={domain.onChange}
            />
            <TextField
              inputProps={{
                readOnly: Boolean(form.isSubmitting),
              }}
              readOnly={action === 'edit'}
              fullWidth
              id="displayName"
              label={t('labelDisplayName')}
              margin="normal"
              name="displayName"
              required
              type="text"
              variant="outlined"
              value={displayName.value}
              onChange={displayName.onChange}
            />
            <TextField
              inputProps={{
                readOnly: Boolean(form.isSubmitting),
              }}
              readOnly={action === 'edit'}
              fullWidth
              id="logoUrl"
              label={t('labelLogoUrl')}
              margin="normal"
              name="logoUrl"
              required
              type="text"
              variant="outlined"
              value={logoUrl.value}
              onChange={logoUrl.onChange}
            />
            <TextField
              inputProps={{
                readOnly: Boolean(form.isSubmitting),
              }}
              readOnly={action === 'edit'}
              fullWidth
              id="privacyUrl"
              label={t('labelPrivacyUrl')}
              margin="normal"
              name="privacyUrl"
              required
              type="text"
              variant="outlined"
              value={privacyUrl.value}
              onChange={privacyUrl.onChange}
            />
             <TextField
              inputProps={{
                readOnly: Boolean(form.isSubmitting),
              }}
              readOnly={action === 'edit'}
              fullWidth
              id="coupon"
              label={t('labelCoupon')}
              margin="normal"
              name="coupon"
              type="text"
              variant="outlined"
              value={coupon.value}
              onChange={coupon.onChange}
            />
            {/* <TextField
              inputProps={{
                readOnly: Boolean(form.isSubmitting),
              }}
              readOnly={action === 'edit'}
              fullWidth
              id="senderEmail"
              label={t('labelSenderEmail')}
              margin="normal"
              name="senderEmail"
              type="email"
              variant="outlined"
              value={senderEmail.value}
              onChange={senderEmail.onChange}
            /> */}
            <TextField
              inputProps={{
                readOnly: Boolean(form.isSubmitting),
              }}
              readOnly={action === 'edit'}
              fullWidth
              id="soc"
              label={t('labelSocialFacebook')}
              margin="normal"
              name="socialFacebook"
              type="text"
              variant="outlined"
              value={socialFacebook.value}
              onChange={socialFacebook.onChange}
            />
            <TextField
              inputProps={{
                readOnly: Boolean(form.isSubmitting),
              }}
              readOnly={action === 'edit'}
              fullWidth
              id="soc"
              label={t('labelSocialInstagram')}
              margin="normal"
              name="socialInstagram"
              type="text"
              variant="outlined"
              value={socialInstagram.value}
              onChange={socialInstagram.onChange}
            />
            <TextField
              inputProps={{
                readOnly: Boolean(form.isSubmitting),
              }}
              readOnly={action === 'edit'}
              fullWidth
              id="soc"
              label={t('labelSocialTwitter')}
              margin="normal"
              name="socialTwitter"
              type="text"
              variant="outlined"
              value={socialTwitter.value}
              onChange={socialTwitter.onChange}
            />
            <TextField
              inputProps={{
                readOnly: Boolean(form.isSubmitting),
              }}
              readOnly={action === 'edit'}
              fullWidth
              id="soc"
              label={t('labelSocialLinkedin')}
              margin="normal"
              name="socialLinkedin"
              type="text"
              variant="outlined"
              value={socialLinkedin.value}
              onChange={socialLinkedin.onChange}
            />
            <div>
              <TransitionAlert className={classes.alert} severity="error" isOpen={form.error}>{form.error}</TransitionAlert>
              <TransitionAlert className={classes.alert} severity="success" isOpen={form.isSuccess}>{t('successMessage')}</TransitionAlert>
            </div>
            <Grid container spacing={1} className={classes.buttons}>
              <Grid item xs={6}>
                <Button 
                  component={Link} 
                  type="button"
                  fullWidth
                  variant="outlined"
                  to="/tenants"  
                >
                    {t('cancel')}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  disabled={form.isSubmitting || isInvalidForm}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleOnSubmit}
                >
                  { form.isSubmitting ? t('saving') : t('save') }
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      )}
    </Container>
  );
}

export default TenantForm;
