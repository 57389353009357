import React from 'react';
import useAuth from '../../components/use-auth';
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import TenantForm from '../../components/tenant-form';

const AddTenantPage = ({path}) => {
  const auth = useAuth();
  return (
    <Layout auth={auth} path={path}>
      <SEO title="Add tenant" />
      { auth && (
        <TenantForm action="add" />
      )}
    </Layout>
  )
}

export default AddTenantPage;
